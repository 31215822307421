/** @typedef {ReturnType<state>} State */
/** @typedef {Record<string, (state: State, data) => void>} Mutation */

export const state = () => ({
  staffMembers: [],
  staffMember: {},
  me: null
});

/**
 * @type {Mutation}
 */
export const mutations = {
  ADD_STAFF_MEMBER: (state, payload) => {
    state.staffMembers.unshift(payload);
  },

  SET_STAFF_MEMBERS(state, payload) {
    state.staffMembers = payload;
  },
  SET_STAFF_MEMBERS_ME(state, payload) {
    state.me = payload;
  },
  SET_STAFF_MEMBER(state, payload) {
    let mems = [...state.staffMembers];
    const memIndex = mems.findIndex((m) => m.id === payload.id);
    mems[memIndex] = Object.assign({}, payload);

    this.commit("staffMembers/SET_STAFF_MEMBERS", mems);
  },

  DELETE_STAFF_MEMBER(state, id) {
    const index = state.staffMembers.findIndex((m) => m.id === id);
    state.staffMembers.splice(index, 1);
  },
};

export const actions = {
  async createStaffMember({ commit }, data) {
    try {
      const res = await this.$axios.post('/staffMembers', data);
      commit('ADD_STAFF_MEMBER', res.data);
      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: `Abbiamo aggiunto ${res.data.firstName} al tuo staff!`,
        show: true,
        timeout: 3000
      }, { root: true });
      return res.data;
    } catch (err) {
      const errorName = err.response.data.name;
      let message = "Ops, qualcosa è andato storto nel creare questo membro dello staff"
      if (errorName === 'unauthorized') {
        message = "Soltanto il manager dell'organizzazione può creare membri dello staff"
      }
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: message,
          show: true,
          timeout: 3000
        }, { root: true });
    }
  },
  async getStaffMembers({ commit }, params) {
    let qs = ''
    if (params) {
      qs = `?${params}`
    }
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.get(`/staffMembers${qs}`);
      commit('SET_STAFF_MEMBERS', res.data);
      return res.data;
    } catch (err) {
      console.warn("Something went wrong while fetching this promoter's staff members", err),
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Ops, qualcosa è andato storto nel reperire il tuo staff",
          show: true,
          timeout: 3000
        }, { root: true });
    }

    commit('SET_LOADING', false, { root: true });
  },
  async getMe({ commit }) {
    try {
      commit('SET_LOADING', true, { root: true });
      const res = await this.$axios.get(`/staffMembers/me`);
      commit('SET_STAFF_MEMBERS_ME', res.data);
      return res.data;
    } catch (err) {
      console.warn("Something went wrong while fetching this promoter's staff members", err);
      return null;
    }

    commit('SET_LOADING', false, { root: true });
  },
  async patchStaffMember({ commit }, data) {
    commit('SET_LOADING', true, { root: true });
    try {
      const res = await this.$axios.patch(`/staffMembers/${data.id}`, data);
      commit('SET_STAFF_MEMBER', res.data);
      commit('SET_LOADING', false, { root: true });
      return true;
    } catch (err) {
      console.warn("Something went wrong while editing this promoter's staff member", err),
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Ops, qualcosa è andato storto nel modificare questo membro dello staff",
          show: true,
          timeout: 3000
        }, { root: true });
      commit('SET_LOADING', false, { root: true });
      return false;
    }
  },
  async reorderStaff({ commit }, { role, positions }) {
    commit('SET_LOADING', true, { root: true });
    try {
      await this.$axios.post(`/staffMembers/reorder`, { role, positions });
      return true;
    } catch (err) {
      return false;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },
  async deleteSubscriptionTemplate({ commit, rootState }, id) {
    commit('SET_LOADING', true, { root: true });
    try {
      const res = await this.$axios.delete(`/subscriptionTemplates/${id}`);
      commit('DELETE_TEMPLATE', id);
      commit("user/SHOW_MESSAGE", {
        color: "success",
        message: "Abbiamo aggiornato i dati con successo",
        show: true,
        timeout: 3000
      }, { root: true });
      return res.data.id;
    } catch (err) {
      console.warn("Something went wrong while deleting this promoter's subscription template", err),
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Ops, qualcosa è andato storto nel cancellare questo pacchetto",
          show: true,
          timeout: 3000
        }, { root: true });
    }

    commit('SET_LOADING', false, { root: true });
    return false;
  }

};
