export const state = () => ({
  recurringSessions: [],
});

export const getters = {

};

export const mutations = {

};

export const actions = {
  async getRecurringSessions({ commit }, params) {
    try {
      const res = await this.$axios.get('/v1/recurringSessions', { params });
      return res.data;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel recuperare i tuoi incontri settimanali",
        show: true,
        timeout: 3000,
      }, { root: true });
      console.warn("Something went wrong with recurring sessions", err);
    } finally {
    }
  },
};
