export const state = () => ({
  reservations: [],
});

export const getters = {}

export const mutations = {
  SET_RESERVATIONS(state, payload) {
    state.reservations = payload;
  },
};

export const actions = {
  async fetchReservations({ commit, rootState }, params) {
    try {
      const res = await this.$axios.get('/reservations', {
        params,
      });
      commit('SET_RESERVATIONS', res.data.reservations);
      return res.data;
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel recuperare le prenotazioni",
        show: true,
        timeout: 3000,
      }, { root: true });
      console.warn("Something went wrong with reservations", err)
    }
  },
  async createReservation({ commit }, data) {
    const params = data.params;
    const reservation = data.reservation;
    try {
      const res = await this.$axios.post(`/v2/reservations`, reservation);
      return res;
    } catch (err) {
      return err.response.data;
    }
  },
  async updateReservation({ commit }, data) {
    const params = data.params;
    const reservation = data.reservation;
    try {
      const res = await this.$axios.put(`/reservations/${reservation.id}`, reservation);
      return res;
    } catch (err) {
      return err.response.data;
    }
  },
  async confirmReservation({ commit }, reservation) {
    try {
      const res = await this.$axios.post(`/reservations/${reservation.id}/confirmations`);
      return res;
    } catch (err) {
      console.log("Something went wrong with the reservation confirmation", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel confermare la tua prenotazione. Controlla che non ci siano sovrapposizioni con altre prenotazioni confermate",
        show: true,
        timeout: 3000,
      }, { root: true })
      return null;
    }
  },
  async cancelReservation({ commit }, { reservation, target = undefined }) {
    try {
      const res = await this.$axios.delete(`/reservations/${reservation.id}`, {
        data: {
          target,
        }
      });
      return res;
    } catch (err) {
      console.log("Something went wrong with the reservation cancellation", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel cancellare la tua prenotazione",
        show: true,
        timeout: 3000,
      }, { root: true })
      return null;
    }
  },
  async putReservationNotes({ commit }, payload) {
    try {
      const res = await this.$axios.put(`/reservations/${payload.reservationId}/notes`, payload.body);
      return res;
    } catch (err) {
      console.log("Something went wrong with the location put", err)
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel modificare la tua prenotazione",
        show: true,
        timeout: 3000,
      }, { root: true })
      return null;
    }
  },
};
